import React from 'react'
import { Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import { FUNERAL_PLAN_EMAIL, FUNERAL_PLAN_TELEPHONE_NUMBER } from 'config'
import { PRODUCTS } from 'lib/products/constants'
import { getStructuredBreadCrumbs } from 'lib/json-ld/getStructuredBreadCrumbs'
import DefaultLayout from 'layouts/DefaultLayout'
import JSONLD from 'components/JSONLD'

import Content from 'views/cremation/public/FuneralPlans/Content'
import { FORMATTED_CREMATION_FUNERAL_PLAN_FEE } from 'views/cremation/shared/constants'

import PATHS from 'paths'

const pageTitle = `Funeral Plans for ${FORMATTED_CREMATION_FUNERAL_PLAN_FEE} - Arrange today with Farewill Funerals`
const pageDescription = `Get peace of mind with a prepaid direct cremation funeral plan for ${FORMATTED_CREMATION_FUNERAL_PLAN_FEE}. No fuss, no hidden extras.`

const FuneralOverview = (): React.ReactElement | null => (
  <Wrapper background={COLOR.WHITE}>
    <JSONLD data={[getStructuredBreadCrumbs({})]} />
    <DefaultLayout
      title={pageTitle}
      description={pageDescription}
      product={PRODUCTS.FUNERAL_PLAN}
      headerColor={COLOR.ACCENT.PRIMARY_60}
      headerType="compact"
      footerType="compact"
      email={FUNERAL_PLAN_EMAIL}
      footerShowFuneralPlansLegalInformation
      telephoneNumber={FUNERAL_PLAN_TELEPHONE_NUMBER}
      usePhoneNumberTracking={false}
      headerFarewillLogoLink={PATHS.CREMATION.FUNERAL_OVERVIEW}
      meta={[
        {
          name: 'robots',
          content: 'noindex,follow',
        },
      ]}
    >
      <Content />
    </DefaultLayout>
  </Wrapper>
)

export default FuneralOverview
